@import "../global";
@import url('https://cdn.jsdelivr.net/npm/select2@4.1.0-beta.1/dist/css/select2.min.css');
                                                                                                             // Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url(https://fonts.googleapis.com/css?family=Lato);@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);@charset "UTF-8";



// Bootstrap Overrides
@import 'variables';

@import "~@coreui/coreui/scss/coreui";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Pages
@import 'pages/dashboard';
